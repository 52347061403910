import tableNav from '../../components/tableNav.vue' //底部导航栏
export default {
    name: 'admin',
    components: {
        tableNav
    },
    data() {
        return {}
    },
    created() {},
    methods: {
        toOrder() {
            this.$router.push({
                name: 'order',
            })
        },
        toAdminInfo() {
            this.$router.push({
                name: 'adminInfo',
            })
        }
    },
}